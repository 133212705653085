import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import moment from 'moment';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import VueCarousel3d from 'vue-carousel-3d';
import { createHead } from '@vueuse/head';
import VueGtm from 'vue-gtm'


SwiperCore.use([Navigation, Pagination]);

loadFonts();

const app = createApp(App);
const head = createHead();
app.use(head);
app.use(router);
app.use(vuetify);
app.use(VueViewer);
app.use(VueCarousel3d);

app.use(VueGtm, {
    id: 'GTM-TWGR388', // Substitua pelo seu ID de rastreamento do Google Tag Manager
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router // Se você estiver usando o vue-router
  })


app.config.globalProperties.$moment = moment;
axios.defaults.baseURL = 'https://controle.ramal4.com.br/api';
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$imgURL = 'https://controle.ramal4.com.br/';
app.config.globalProperties.$mensagemSucesso=function(mensagem){
    Swal.fire(" ",mensagem, "success");
}
app.config.globalProperties.$mensagemErro=function(mensagem){
    Swal.fire(" ",mensagem, "error");
}

app.mount('#app');