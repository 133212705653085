<template>
  <v-app>
    <v-main>
      <Header/>
      <router-view :key="$route.fullPath"/>
      <FooterPage/>
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage
  },
  data: () => ({
    ecosystem: []
  }),
  methods: {

  },
  created () {

  }
}
</script>
<style>
  @import "@/assets/css/open-iconic-bootstrap.min.css";
  @import "@/assets/css/animate.css";
  @import "@/assets/css/owl.theme.default.min.css";
  @import "@/assets/css/magnific-popup.css";
  @import "@/assets/css/aos.css";
  @import "@/assets/css/ionicons.min.css";
  @import "@/assets/css/bootstrap-datepicker.css";
  @import "@/assets/css/jquery.timepicker.css";
  @import "@/assets/css/flaticon.css";
  @import "@/assets/css/icomoon.css";
  @import "@/assets/css/style.css";


  @media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
    .tamfonte{
      font-size: 25px !important;
    }
    .imglogo{
      width: 250px;
    }

    .alttela{
      padding-top: 60px;
    }

    .overlayTopo2{
  position: absolute;
  top: 58%;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
    
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

    .imglogo{
      width: 280px;
    }

    .alttela{
      padding-top: 50px;
    }
    .overlayTopo2{
      position: absolute;
      top: 68%;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }
  
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }

    .imglogo{
      width: 300px;
    }
    .alttela{
      padding-top: 40px;
    }

    .overlayTopo2{
      position: absolute;
      top: 38%;
      left: 0;
      width: 100%;
      height: 250px;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
    .imglogo{
      width: 320px;
    }

    .alttela{
      padding-top: 20px;
    }

    .overlayTopo2{
  position: absolute;
  top: 42%;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
}

 /* WHATSAPP FIXO */
 .whatsapp-fixo {
      position: fixed;
      bottom: 6px;
      right: 55px;
      z-index: 999;
  }
</style>
