
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import ComunicacaoDetalhe from '../views/ComunicacaoDetalheView.vue'
import Contato from '../views/ContatoView.vue'
import SerralheriaDetalhe from '../views/SerralheriaDetalheView.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/comunicacaodetalhe', name: 'comunicacaodetalhe', component: ComunicacaoDetalhe },
  { path: '/serralheriadetalhe', name: 'serralheriadetalhe', component: SerralheriaDetalhe },
  { path: '/contato', name: 'contato', component: Contato },
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  window.scrollTo({ top:0,behavior:'smooth'});
  next();
});

export default router
