<template>
  <div>
	<footer class="ftco-footer ftco-section img">
	    	<div class="overlay"></div>
	      <div class="container">
	        <div class="row mb-5">
	          <div class="col-md-4">
	            <div class="ftco-footer-widget mb-6" v-if="rodape!= ''">
	              <h2 class="ftco-heading-2"><router-link to="/"><img src="images/AA RAMAL LOGOMARCA.JPG" style="width: 250px; border-radius: 15px;" alt=""></router-link></h2>
	              <p v-html="rodape[0].resumo1" style="text-align: justify;"> </p>
	              <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
	                <li class=""><a v-if="redes!='' && redes[0].nome!=''" :href="redes[0].nome" target="_blank" title="Facebook"><span class="icon-facebook"></span></a></li>
	                <li class=""><a v-if="redes!='' && redes[0].referencia!=''" :href="redes[0].referencia" target="_blank" title="Instagram"><span class="icon-instagram"></span></a></li>
					<li class=""><a v-if="redes!='' && redes[0].campoExtra1!=''" :href="redes[0].campoExtra1" target="_blank" title="Youtube"><span class="icon-youtube"></span></a></li>
					<li class=""><a v-if="redes!='' && redes[0].campoExtra2!=''" :href="redes[0].campoExtra2" target="_blank" title="Linkedin"><span class="icon-linkedin"></span></a></li>
	              </ul>
	            </div>
	          </div>
	          <div class="col-md-3" v-if="rodape!= ''">
	            <div class="ftco-footer-widget mb-2">
	              <h2 class="ftco-heading-2">Selos de qualidade</h2>
	              <div style="text-align: center;">
	                 <img v-if="rodape[0].institucionalImg!=''" :src="$imgURL + rodape[0].institucionalImg[0].urlImagem" style="width: 150px;"  alt=""  >
	              </div>
	              <div style="text-align: center;">
					<img v-if="rodape[0].institucionalImg!=''" :src="$imgURL + rodape[0].institucionalImg[1].urlImagem" style="width: 150px;"  alt="">
	               
	              </div>
	            </div>
	          </div>
	          <div class="col-md-2">
	             <div class="ftco-footer-widget mb-4 ml-md-4">
	              <h2 class="ftco-heading-2">Links Úteis</h2>
	              <ul class="list-unstyled">
					<li><router-link to="/" class="py-2 d-block" title="Home">Home</router-link></li>
	                <li><router-link to="/comunicacaodetalhe" class="py-2 d-block" title="Comunicação Visual">Comunicação Visual</router-link></li>
	                <li><router-link to="/serralheriadetalhe" class="py-2 d-block" title="Serralheria">Serralheria</router-link></li>
	                <li><router-link to="/contato" class="py-2 d-block" title="Contato">Contato</router-link></li>
	              </ul>
	            </div>
	          </div>
	          <div class="col-md-3" v-if="rodape!= ''">
	            <div class="ftco-footer-widget mb-4">
	            	<h2 class="ftco-heading-2">Contatos</h2>
	            	<div class="block-23 mb-3">
		              <ul>
		                <li><span class="icon icon-map-marker"></span><span class="text">{{ rodape[0].nome }}</span></li>
		                <li><span class="icon icon-phone"></span><span class="text">{{ rodape[0].referencia }}</span></li>
						<li><span class="icon icon-envelope"></span><span class="text">{{ rodape[0].campoExtra1 }}</span></li>
		              </ul>
		            </div>
	            </div>
				
	        </div>
	        </div>
			
	        <div class="row">
	          <div class="col-md-12 text-center">
				<a href="https://avancedigital.com.br/" target="_blank"><img src="/images/avanceBranco.png" alt=""></a>
	          </div>
	        </div>
	      </div>
	    </footer>
		<div v-if="rodape!=''" class="whatsapp-fixo">
				<a :href="rodape[0].campoExtra3" target="_blank" aria-label="Avance Digital" title="Avance Digital">
          		<img src="/images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
			    </a> 
			</div>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';

export default {
  name: 'PageFooter',
  components: {},
  data: () => ({
    ecosystem: [],
	rodape: [],
	redes: [],

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
	//  rodapé
	axios.get('/institucional/telas/informacoes')
			.then(response => {

				this.rodape = response.data;
			})
			.catch(error => {
				console.error(error);
			});
	//redes
	axios.get('/institucional/telas/redes')
			.then(response => {

				this.redes = response.data;
			})
			.catch(error => {
				console.error(error);
			});
  }
}
</script>

<style scoped>
</style>
