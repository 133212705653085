<template>
  <div>

  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';

export default {
  name: 'PageHeader',
  components: {},
  data: () => ({
   

  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    
  }
}
</script>

<style scoped>

</style>
