<template>
  <div>
	  <header>
        <section class="colorlib-navbar-brand">
			<div class="overlayTopo"></div>
			<div class="container">
				<div class="row">
					<div class="col-6" style="margin-left: 15px;">
						<router-link to="/" aria-label="Logo Ramal 4"> 
							<img src="/images/logoRamal4.jpg" class="imglogo" style="border-radius: 15px;" alt="Logo Ramal 4" title="Logo Ramal 4" />
						</router-link>
					</div>
					<div class="col-6"></div>
				</div>
			</div>
         
        </section>
		
      </header>
      <section class="home-slider owl-carousel" v-if="topo!= ''">
        <div class="slider-item" :style="{ backgroundImage: 'url(' + $imgURL + topo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
		  <div class="overlayTopo2"></div>
          <div id="txtfundo" class="container alttela">
            <div class="row slider-text justify-content-start align-items-center" data-scrollax-parent="true">
              <div class="col-lg-5 header-left">
                <p class="hidden-xs hidden-sm">
                  <router-link :to="topo[0].campoExtra2" class="btn btn-primary px-2 py-3 " style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra1}}</router-link> 
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-3 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra3}}</router-link> 
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-2 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;">{{topo[0].campoExtra5}}</router-link> 
				</p>
				<p class="hidden-md hidden-lg">
					<router-link :to="topo[0].campoExtra2" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-bottom: 10px !important;">{{topo[0].campoExtra1}}</router-link><br>
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra3}}</router-link> <br>
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra5}}</router-link> 
				</p>
              </div>
			  <div class="col-lg-7">
				<!--span class="position" style="text-shadow: #000 1px 2px;">{{topo[0].nome}}</!--span>
                <h1-- class="mb-4 tamfonte" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style="text-shadow: #000 1px 2px;" >{{topo[0].referencia}}</h1-->
                <div class="d-md-flex models-info">
                	<div>
                		<p v-html="topo[0].descricao1" style="text-align:justify;color:#000000 !important;"> </p>
                	</div>
                </div>
			  </div>
            </div>
          </div>
        </div>
      </section>
    <div id="colorlib-page">
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="row model-detail d-mf-flex align-items-center">
                <div class="col-md-6" v-if="cadcoms != ''">
                  <v-carousel cycle :show-arrows="true" hide-delimiters>
                    <v-carousel-item cover class="item" v-for="cadcom in cadcoms[0].institucionalImg.slice(0, 3)"
                      :key="cadcom.institucionalId">
                      <div
                        :style="{ backgroundImage: 'url(' + $imgURL + cadcom.urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '600px' }">
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </div>
                <div class="col-md-6 model-info " v-if="cadcoms != ''">
                  <h3 class="mb-4">{{ cadcoms[0].nome }}</h3>
                  <p v-html="cadcoms[0].descricao1" style="text-align: justify;"></p>
                  <p class="mt-4"><router-link to="/contato" class="btn btn-primary py-3 px-4" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">Contato</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section-2 mb-5 pb-5" v-if="cadcoms != ''">
        <div class="container-fluid">
          <div class="row d-flex no-gutters">
            <div class="col-md-3 model-entry" v-for="cadcom in cadcoms[0].institucionalImg" :key="cadcom.institucionalId">
              <div class="model-img" v-if="cadcom.urlImagem != ''"
                :style="{ backgroundImage: 'url(' + $imgURL + cadcom.urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="model-info">
      <h3 class="mb-4" style="text-align: center;">Peça seu orçamento personalizado!</h3>
    </div>
    <!-- Aqui vai o formulario-->
    <div class="container">
      <div class="row">
        <div class="col-3" hidden-xs></div>
        <div class="col-md-6 col-xs-12">
          <PageFormulario></PageFormulario>
        </div>
        <div class="col-3" hidden-xs></div>
      </div>
    </div>
    <div class="section">
          <PageParceiro></PageParceiro>
    </div>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import PageFormulario from '../components/PageFormulario.vue'
import PageParceiro from '../components/PageParceiros.vue'
import { useHead } from '@vueuse/head';

export default {
  name: 'ComunicacaoDetalheView',
  components: {
    PageFormulario,
    PageParceiro
  },
  data: () => ({
    ecosystem: [],
    topo: [],
    cadcoms: [],
  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
    carregaMetas(value) {
			let pElement = document.createElement('p');
            pElement.innerHTML = value[0].descricao1;
           let textoSemHTML = pElement.textContent || pElement.innerText;
			useHead({
				title: 'Comunicação Visual - Ramal 4',
				meta: [
					{ name: 'description', content: textoSemHTML },
					{ property: 'og:title', content: 'Comunicação Visual - Ramal 4' },
          { property: 'og:description', content: textoSemHTML },
				],

			})
		}
  },
  created() {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
    // Servicos Topo
    axios.get('/institucional/telas/comunicacaotopo')
      .then(response => {

        this.topo = response.data;
        this.carregaMetas(this.topo);
      })
      .catch(error => {
        console.error(error);
      });
    //comunicacao
    axios.get('/institucional/telas/cadastrocomunicacao')
      .then(response => {

        this.cadcoms = response.data;

      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
.overlayTopo {
	position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 123px;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: 1;
}

img {
  position: relative;
  z-index: 2;
}


#txtfundo{
  position: relative;
  z-index: 2;
}

.owl-carousel.home-slider .slider-item .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    color: rgba(0, 0, 0) !important;
}
</style>
