<template>
  <div v-if="imagens.length > 0" style="padding-top: 80px;padding-bottom: 60px;">
    <div class="model-info" style="padding-bottom: 40px;">
      <h3 class="mb-4" style="text-align: center;">Clientes e parceiros que atendemos!</h3>
    </div>
    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="3" 
    :breakpoints="{1300: { slidesPerView: 7 }, 1000: { slidesPerView: 5 },600: { slidesPerView: 4 }, 0: { slidesPerView: 1 } }" class="carousel-testimony owl-carousel">
      <SwiperSlide v-for="parceiro in imagens" :key="parceiro.institucionalId">
        <img :src="$imgURL + parceiro.urlImagem" style="width: 100%;padding-bottom: 60px;" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'PageParceiros',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    parceiros: [],
    imagens: [],
  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
  },
  created() {
    // sobre home
    axios.get('/institucional/telas/clientes')
      .then(response => {

        this.parceiros = response.data;
        console.log('this.parceiros')
        console.log(this.parceiros)
        if (this.parceiros[0].institucionalImg != '') {
          this.imagens = this.parceiros[0].institucionalImg;
        }
      })
      .catch(error => {
        console.error(error);
      });

  },
  mounted() {
  },
}
</script>

<style scoped></style>
