<template>
	<div>
	  <header>
        <section class="colorlib-navbar-brand">
			<div class="overlayTopo"></div>
			<div class="container">
				<div class="row">
					<div class="col-6" style="margin-left: 15px;">
						<router-link to="/" aria-label="Logo Ramal 4"> 
							<img src="/images/logoRamal4.jpg" class="imglogo" style="border-radius: 15px;" alt="Logo Ramal 4" title="Logo Ramal 4" />
						</router-link>
					</div>
					<div class="col-6"></div>
				</div>
			</div>
         
        </section>
		
      </header>
      <section class="home-slider owl-carousel" v-if="topo!= ''">
        <div class="slider-item" :style="{ backgroundImage: 'url(' + $imgURL + topo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
		  <div class="overlayTopo2"></div>
          <div id="txtfundo" class="container alttela">
            <div class="row slider-text justify-content-start align-items-center" data-scrollax-parent="true">
              <div class="col-lg-5 header-left">
                <p class="hidden-xs hidden-sm">
					<router-link :to="topo[0].campoExtra2" class="btn btn-primary px-2 py-3 " style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra1}}</router-link> 
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-3 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra3}}</router-link> 
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-2 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;">{{topo[0].campoExtra5}}</router-link> 
				</p>
				<p class="hidden-md hidden-lg">
					<router-link :to="topo[0].campoExtra2" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-bottom: 10px !important;">{{topo[0].campoExtra1}}</router-link><br>
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra3}}</router-link> <br>
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra5}}</router-link> 
				</p>
              </div>
			  <div class="col-lg-7">
				<!--span class="position" style="text-shadow: #000 1px 2px;">{{topo[0].nome}}</!--span>
                <h1-- class="mb-4 tamfonte" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style="text-shadow: #000 1px 2px;" >{{topo[0].referencia}}</h1-->
                <div class="d-md-flex models-info">
                	<div>
                		<p v-html="topo[0].descricao1" style="text-align:justify;color:#000000 !important;"> </p>
                	</div>
                </div>
			  </div>
            </div>
          </div>
        </div>
      </section>
 	  <!-- Sobre -->
      <section class="ftco-section-2" v-if="sobre!= ''">
	      <div class="container-fluid">
	        <div class="section-2-blocks-wrapper d-flex row no-gutters">
	          <div class="img col-md-6 " :style="{ backgroundImage: 'url(' + $imgURL + sobre[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', }">
	          </div>
	          <div class="text col-md-6 ">
	            <div class="text-inner align-self-start">
	              <h3 class="heading">{{sobre[0].nome}}</h3>
	              <p v-html="sobre[0].descricao1" style="text-align: justify;"></p>
	            </div>
	          </div>
	        </div>
	      </div>
	  </section>
 	  <!-- Serviços -->
	  <section class="ftco-section-2" v-if="servicos!= ''">
	    	<div class="container-fluid">
	    		<div class="row d-flex no-gutters">
	    			<div class="col-md-6 d-flex align-items-center heading-section  bg-light">
	    				<div class="col-md-12">
							<div class="p-7">
			    			<h2 class="mb-5">{{servicos[0].nome}}</h2>
		              		<p v-html="servicos[0].descricao1" style="text-align: justify;"></p>
	              			</div>
              			</div>
	    			</div>
	    			<div class="col-md-3 model-entry ">
		    			<div class="model-img" :style="{ backgroundImage: 'url(' + $imgURL + servicos[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
		    				<div class="name">
		    					<a class="btn btn-primary " href="#" style="background: #000000; border: 1px solid #000000;width: 100%;"><h3 style="font-size: 20px;"><router-link :to="servicos[0].campoExtra3" style="color: #f7c101;">{{servicos[0].campoExtra1}}</router-link></h3></a>
		    				</div>
		    				<div class="text">
		    				<h3 ><router-link :to="servicos[0].campoExtra3"> {{servicos[0].campoExtra1}}</router-link></h3>
		    				<div class="d-flex models-info">
		    				<div class="pr-md-3">
	                	</div>
	                	<div class="pr-md-3">
	                		<p>{{servicos[0].campoExtra2}}</p>
	                	</div>
		    					</div>
		    				</div>
		    			</div>
		    		</div>
		    		<div class="col-md-3 model-entry ">
		    			<div class="model-img" :style="{ backgroundImage: 'url(' + $imgURL + servicos[0].institucionalImg[1].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }" >
		    				<div class="name">
								<a class="btn btn-primary" href="#" style="background: #000000; border: 1px solid #000000;width: 100%;"><h3 style="font-size: 20px;"><router-link :to="servicos[0].campoExtra6" style="color: #f7c101;">{{servicos[0].campoExtra4}}</router-link></h3></a>
		    				</div>
		    				<div class="text">
		    				<h3><router-link :to="servicos[0].campoExtra6">{{servicos[0].campoExtra4}}</router-link></h3>
		    				<div class="d-flex models-info">
		    				<div class="pr-md-3">
	                	</div>
	                	<div class="pr-md-3">
	                		<p>{{servicos[0].campoExtra5}}</p>
	                	</div>
		    		</div>
		    	</div>
		    </div>
	    </div>
	</div>
</div>
	  </section>
	  <!-- Depoimentos -->
	  <section class="ftco-section testimony-section img"  v-if="depofundo!= ''">
			<div class="overlay"></div>
			    <div class="container">
			    <div class="row justify-content-center">
			      	<div class="col-md-10 mb-5 text-center heading-section ">
			            <h2 class="mb-4">{{ depofundo[0].nome }}</h2>
			            <p></p>
			          </div>
			      	</div>
			        <div class="row d-md-flex justify-content-center">
		        	<div class="col-12-md" v-if="depoimentos.length > 0">
						<v-carousel cycle :show-arrows="false" hide-delimiter-background  style="height: 100%;padding-bottom: 60px;">
							   <v-carousel-item cover class="item" v-for="depoimento in depoimentos" :key="depoimento.institucionalId">
								<div class="testimony-wrap text-center">
									<div class="user-img mb-5" :style="{ backgroundImage: 'url(' + $imgURL + depoimento.institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
										<span class="quote d-flex align-items-center justify-content-center">
										<i class="icon-quote-left"></i>
										</span>
									</div>
									<div class="text">
									<p v-html="depoimento.descricao1" class="mb-5"></p>
									<p class="name">{{depoimento.nome}}</p>
									<span class="position">{{depoimento.campoExtra1}}</span>
									</div>
									</div>
							   </v-carousel-item>
						</v-carousel>
		          </div>
		        </div>
		      </div>
	  </section>
	  <!-- Banner Contato -->
	  <section class="ftco-quote " v-if="callhome!= ''">
	    	<div class="container">
	    		<div class="row d-flex">
	    			<div class="col-md-9 req-quote py-5 align-items-center img" 
					:style="{ backgroundImage: 'url(' + $imgURL + callhome[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
		    			<h3 class="ml-md-3" style="color:#000000;">{{callhome[0].nome}}</h3>
		    			<span class="ml-md-3"><router-link :to="callhome[0].campoExtra1" style="background: #000000; border: 1px solid #000000;">{{callhome[0].referencia}}</router-link></span>
	    			</div>
	    		</div>
	    	</div>
	  </section>
	</div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
	name: "HomeView",
    components: {
		moment,
	},
	data: () => ({
		date: '2023-05-15',
		topo: [],
		sobre: [],
		servicos: [],
		depofundo: [],
		depoimentos: [],
		callhome: [],
	}),
	methods: {
		// Aqui vai a chamada das funções, que são validações.
		formattedDate(value) {
			return this.$moment(value).format('DD/MM/YYYY');
		},
		carregaMetas(value) {
			let pElement = document.createElement('p');
            pElement.innerHTML = value[0].descricao1;
           let textoSemHTML = pElement.textContent || pElement.innerText;
			useHead({
				title: 'Home - Ramal 4 Comunicação Visual',
				meta: [
					{ name: 'description', content: textoSemHTML },
					{ property: 'og:title', content: 'Home - Ramal 4 Comunicação Visual' },
                    { property: 'og:description', content: textoSemHTML },
				],

			})
		}
	},
	created() {
		// É quando roda a tela, busca a informação e carrega em variáveis que estipular.
		//  topo home 
		axios.get('/institucional/telas/topohome')
			.then(response => {
				this.topo = response.data;
				this.carregaMetas(this.topo);
			})
			.catch(error => {
				console.error(error);
			});

		// sobre home
		axios.get('/institucional/telas/sobrehome')
			.then(response => {

				this.sobre = response.data;
			})
			.catch(error => {
				console.error(error);
			});
				// sobre home
		axios.get('/institucional/telas/servicoshome')
			.then(response => {

				this.servicos = response.data;
			})
			.catch(error => {
				console.error(error);
			});
			//depoimentos fundo
		axios.get('/institucional/telas/depoimentosfundo')
			.then(response => {

				this.depofundo = response.data;
			})
			.catch(error => {
				console.error(error);
			});
			//depoimentos fundo
		axios.get('/institucional/telas/depoimentos')
			.then(response => {

				this.depoimentos = response.data;
			})
			.catch(error => {
				console.error(error);
			});
		//call home
		axios.get('/institucional/telas/callaction')
			.then(response => {

				this.callhome = response.data;
			})
			.catch(error => {
				console.error(error);
			});
	},
};
</script>

<style>
.overlayTopo {
	position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 123px;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: 1;
}

img {
  position: relative;
  z-index: 2;
}



#txtfundo{
  position: relative;
  z-index: 2;
}

.owl-carousel.home-slider .slider-item .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    color: rgba(0, 0, 0) !important;
}

</style>
