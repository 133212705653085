<template>
  <div>
    <form action="#">
                  <div class="form-group">
                    <input v-model="formNome" type="text" class="form-control" placeholder="Nome">
                  </div>
                  <div class="form-group">
                    <input  v-model="formEmail" type="text" class="form-control" placeholder="E-mail">
                  </div>
                  <div class="form-group">
                    <input v-model="formFone" type="text" class="form-control" placeholder="Telefone">
                  </div>
                  <div class="form-group">
                    <textarea v-model="formMensagem" name="" id="" cols="30" rows="7" class="form-control" placeholder="Mensagem"></textarea>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary py-3 px-5" @click.prevent="enviarDadosFormulario" style="background: #000000; border: 1px solid #000000;color: #f7c101;">Enviar mensagem</button>
                  </div>
    </form>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';

export default {
  name: 'PageFormulario',
  components: {},
  data: () => ({
    contato: {
      NomeEmpresa: "",
      Assunto: "",
      Destinatario: "",
      EmailResposta: "",
      TextoEmail: ""
    },
    formEmail: '',
    formNome: '',
    formFone: '',
    formMensagem: '',
  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
    enviarDadosFormulario(){
      // Aqui precisa validar se os campos estão preenchidos.
    if (this.formNome==''){
      this.$mensagemErro('Nome não preenchido.')
    }
    else if (this.formEmail==''){
      this.$mensagemErro('E-mail não preenchido.')
    }
    else if (this.formFone==''){
      this.$mensagemErro('Telefone não preenchido.')
    }
   else {
      this.contato.NomeEmpresa='Ramal 4';
      this.contato.Assunto='Contato do Site';
      this.contato.Destinatario=this.infocontato[0].referencia;
      this.contato.EmailResposta=this.formEmail;
      this.contato.TextoEmail='<strong>Nome: </strong>' + this.formNome + "<br>" +
                              '<strong>E-mail: </strong>' + this.formEmail + "<br>" +
                              '<strong>Telefone: </strong>' + this.formFone + "<br> <br>" +
                              '<strong>Mensagem: </strong> <br>' + this.formMensagem + "<br> <br>" +
                              '<strong>Atenciosamente, <br> Equipe Ramal 4 </strong>' ;
      console.log(this.contato)
      // Disparar o envio do e-mail.
	 
      axios.post('/contato/enviamail', this.contato)
        .then(response => {
          this.$mensagemSucesso(response.data);
          this.formNome= '';
          this.formEmail= '';
          this.formFone='';
          this.formMensagem= '';
        })
        .catch(error => {
          this.$mensagemErro(error);
        });                      
   } 
    }
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
     // Info contato
     axios.get('/institucional/telas/infocontato')
        .then(response => {
        
        this.infocontato = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>

</style>
