<template>
  <div>
	  <header>
        <section class="colorlib-navbar-brand">
			<div class="overlayTopo"></div>
			<div class="container">
				<div class="row">
					<div class="col-6" style="margin-left: 15px;">
						<router-link to="/" aria-label="Logo Ramal 4"> 
							<img src="/images/logoRamal4.jpg" class="imglogo" style="border-radius: 15px;" alt="Logo Ramal 4" title="Logo Ramal 4" />
						</router-link>
					</div>
					<div class="col-6"></div>
				</div>
			</div>
         
        </section>
		
      </header>
      <section class="home-slider owl-carousel" v-if="topo!= ''">
        <div class="slider-item" :style="{ backgroundImage: 'url(' + $imgURL + topo[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }">
		  <div class="overlayTopo2"></div>
          <div id="txtfundo" class="container alttela">
            <div class="row slider-text justify-content-start align-items-center" data-scrollax-parent="true">
              <div class="col-lg-5 header-left">
                <p class="hidden-xs hidden-sm">
                  <router-link :to="topo[0].campoExtra2" class="btn btn-primary px-2 py-3 " style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra1}}</router-link> 
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-3 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-right: 10px;">{{topo[0].campoExtra3}}</router-link> 
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-2 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;">{{topo[0].campoExtra5}}</router-link> 
				</p>
				<p class="hidden-md hidden-lg">
					<router-link :to="topo[0].campoExtra2" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101; margin-bottom: 10px !important;">{{topo[0].campoExtra1}}</router-link><br>
					<router-link :to="topo[0].campoExtra4" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra3}}</router-link> <br>
					<router-link :to="topo[0].campoExtra6" class="btn btn-primary px-4 py-3" style="background: #000000; border: 1px solid #000000;color: #f7c101;  margin-bottom: 10px !important;">{{topo[0].campoExtra5}}</router-link> 
				</p>
              </div>
			  <div class="col-lg-7">
				<!--span class="position" style="text-shadow: #000 1px 2px;">{{topo[0].nome}}</!--span>
                <h1-- class="mb-4 tamfonte" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style="text-shadow: #000 1px 2px;" >{{topo[0].referencia}}</h1-->
                <div class="d-md-flex models-info">
                	<div>
                		<p v-html="topo[0].descricao1" style="text-align:justify;color:#000000 !important;"> </p>
                	</div>
                </div>
			  </div>
            </div>
          </div>
        </div>
      </section>
    <section class="ftco-section contact-section" style="padding-top: 20px;"  v-if="infocontato != ''">
          <div class="container mt-5">
            <div class="row block-9">	
  			      <div class="col-md-6 contact-info ">
  				      <div class="row">
  				        <div class="col-md-12 mb-4">
  		              <h2 class="h4">Informaçoes para contato</h2>
  		            </div>
  		            <div class="col-md-12 mb-3">
  		            <p v-html="infocontato[0].descricao1" ></p>
  		            </div>
  				        </div>
  			          </div>	
                  <div class="col-md-6  ">
               <!-- Aqui vai o formulario-->
               <PageFormulario>
               </PageFormulario>
              </div>
		
            </div>
          </div>
    </section>
    <div v-if="infocontato!=''">
      <iframe :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + infocontato[0].campoExtra1 "
              width="100%" height="650px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0">
      </iframe>
  	</div>
  </div>
</template>

<script>
// Aqui vão os inports. Plugins e componentes.
import axios from 'axios';
import PageFormulario from '../components/PageFormulario.vue'
import { useHead } from '@vueuse/head';

export default {
  name: 'ContatoView',
  components: {
    PageFormulario,
  },
  data: () => ({
   teste:[],
   topo: [],
   infocontato: [],
  }),
  methods: {
    // Aqui vai a chamada das funções, que são validações.
    carregaMetas(value) {
			let pElement = document.createElement('p');
            pElement.innerHTML = value[0].descricao1;
           let textoSemHTML = pElement.textContent || pElement.innerText;
			useHead({
				title: 'Contato - Ramal 4',
				meta: [
					{ name: 'description', content: textoSemHTML },
					{ property: 'og:title', content: 'Contato - Ramal 4' },
          { property: 'og:description', content: textoSemHTML },
				],

			})
		}
  },
  created () {
    // É quando roda a tela, busca a informação e carrega em variáveis que estipular.
     // Contato topo
     axios.get('/institucional/telas/contatotopo')
        .then(response => {
        
        this.topo = response.data;
        this.carregaMetas(this.topo);
      })
      .catch(error => {
        console.error(error);
      });
       // Info contato
       axios.get('/institucional/telas/infocontato')
        .then(response => {
        
        this.infocontato = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
.overlayTopo {
	position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 123px;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: 1;
}

img {
  position: relative;
  z-index: 2;
}

#txtfundo{
  position: relative;
  z-index: 2;
}

.owl-carousel.home-slider .slider-item .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    color: rgba(0, 0, 0) !important;
}
</style>
